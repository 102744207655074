import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HOME',
    component: () => import('../components/Home.vue')
  },
  {
    path: '/login',
    name: 'LOGIN',
    component: () => import('../components/Login.vue')
  },
  {
    path: '/categories',
    name: 'CATEGORIAS',
    component: () => import('../components/Categories.vue')
  },
  {
    path: '/products',
    name: 'PRODUCTOS',
    component: () => import('../components/Products.vue')
  },
  {
    path: '/my_products',
    name: 'MIS PRODUCTOS',
    component: () => import('../components/MyProducts.vue')
  },
  {
    path: '/orders',
    name: 'PEDIDOS',
    component: () => import('../components/Orders.vue')
  },
  {
    path: '/ventas',
    name: 'VENTAS',
    props: true,
    component: () => import('../components/Ventas.vue')
  },
  {
    path: '/clients_in_the_area',
    name: 'CLIENTES EN LA ZONA',
    props: true,
    component: () => import('../components/ClientsInTheArea.vue')
  },

  {
    path: '/chat',
    name: 'CHAT',
    props: true,
    component: () => import('../components/Chat.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router