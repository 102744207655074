import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

/*createApp(App)  
  .use(router)
  .use(vuetify)  
  .mount('#app')*/

let app = createApp(App)
app.config.globalProperties.is_connected = 'false'
app.use(vuetify)
app.use(router).mount('#app')